.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}
#form {
  padding: 10px;
  border: 1px solid;
  
}

#check-box {
  margin-top: 10px;
}

#app-container {
  width: 900px;
  position: center;
}
.dx-texteditor-input {
  font-size: 14px; /* Adjust font size */
  padding: 8px; /* Adjust padding */
  border-radius: 4px; /* Optional: Add border radius */
  border: 1px solid #ccc; /* Optional: Add border */
  width: 100%; /* Optional: Adjust width */
}

.card-container {
  width: 100%; /* Full width */
  max-width: 900px; /* Maximum width */
  margin: 20px auto; /* Center align horizontally with margin */
  padding: 20px; /* Padding inside the card */
  background-color: #ffffff; /* Background color of the card */
  border: 1px solid #dddddd; /* Border color and thickness */
  border-radius: 8px; /* Rounded corners for a card-like appearance */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for the card */
}
